import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from "@restart/ui/esm/Button";
import { Link, useHistory } from "react-router-dom";
import HeaderImprimir from "../../components/headerImprimir";
import * as PrintAndPractice from "../../services/api/PrintAndPractice";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import abraCaixaApp from "../../assets/jogos/abra_caixa.jpeg";
import associacaoApp from "../../assets/jogos/associacao.jpeg";
import cacaApp from "../../assets/jogos/caca.jpeg";
import cartaApp from "../../assets/jogos/carta.jpeg";
import combineApp from "../../assets/jogos/combine.jpeg";
import encontreApp from "../../assets/jogos/encontre.jpeg";
import forcaApp from "../../assets/jogos/forca.jpeg";
import questionarioApp from "../../assets/jogos/questionario.jpeg";
import quizApp from "../../assets/jogos/quiz.jpeg";
import roletaApp from "../../assets/jogos/roleta.jpeg";
import vireApp from "../../assets/jogos/vire.jpeg";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles({
  botaoPrimeiro: {
    marginRight: "40px",
    backgroundColor: "#54B5CF",
    borderColor: "#54B5CF",
    borderRadius: "25px",
    color: "#fff",
    float: "right",
    fontFamily: "Roboto",
    fontSize: "22px",
    fontStyle: "bold",
    fontWeight: "bold",
    height: "50px",
    margin: "40px 0 0 0",
    padding: "10px 60px 20px 60px",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#0facc4",
      color: "#fff",
    },
    "@media (max-width: 500px)": {
      backgroundColor: "#54B5CF",
      fontSize: "20px",
      padding: "10px 20px 0 20px",
      margin: "10px 0 0 0",
    },
  },
  appImageContainer: {
    position: "relative",
    width: "95%",
    height: "250px",
    borderRadius: "20px",
    overflow: "hidden",
  },
  appImage: {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    overflow: "hidden",
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  appTitle: {
    color: "#3C6C98",
    textAlign: 'center'
  },
  modalCloseButton: {
    backgroundColor: "#54B5CF",
    borderRadius: "25px",
    color: "#fff",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "40px",
    marginTop: "20px",
    padding: "10px 30px",
    border: "none",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#0facc4",
      color: "#fff",
    },
  },
  modalAppImage: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    borderRadius: "20px",
    overflow: "hidden",
  },
  modalAppTitle: {
    color: "#3C6C98",
    textAlign: "center",
    marginTop: "10px",
  },
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "90vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
};

export default function Print() {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [applications, setApplications] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedApp, setSelectedApp] = React.useState(null);

  const handleOpenWithDocs = (app) => {
    setSelectedApp(app);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setSelectedApp(null);
  };

  React.useEffect(() => {
    (async () => {
      const resp = await PrintAndPractice.findAll();
      setApplications(resp.data);
      setLoading(false);
    })();
  }, [page]);

  return (
    <div className={classes.container}>
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color={"error"} />
        </Box>
      ) : null}

      <HeaderImprimir />
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ padding: "20px" }}
      >
        {applications.map((app, index) => (
          <Grid item xs={12} md={3} key={index} style={{ textAlign: 'center' }}>
            <div
              onClick={() => handleOpenWithDocs(app)}
              style={{ cursor: "pointer" }}
            >
              <div className={classes.appImageContainer}>
                <div
                  className={classes.appImage}
                  style={{ backgroundImage: `url(${app.thumbnail})` }}
                ></div>
              </div>
            </div>
            <small className={classes.appTitle}><strong>{app.title}</strong></small>
            <br />
            <br />
          </Grid>
        ))}
      </Grid>

      <Button className={classes.botaoPrimeiro} href="/admin/profissional/perfil">
        VOLTAR
      </Button>

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Documentos disponíveis
          </Typography>

          {selectedApp && selectedApp.document && (
            <Box sx={{ marginTop: 2, marginBottom: 2 }}>
              <List>
                {selectedApp.document.sort().map((doc, index) => (
                  <>
                    <a
                      href={`https://files.minhafono.com.br/archives/pdf/${doc}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{textDecoration: 'none'}}
                    >
                      <ListItem>
                        <ListItemText
                          primary={`${doc}`}
                        />
                      </ListItem>
                    </a>
                  </>
                ))}
              </List>
            </Box>
          )}

          <Button className={classes.modalCloseButton} onClick={handleClose}>
            FECHAR
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
