                                                                                                                                                          import React from 'react';
import Routes from './routes';

import { Provider } from 'react-redux';
import store from './redux/store/index';
import { UserProvider } from './providers/useUserContext';

function App() {
  return (
    <Provider store={store}>
      <UserProvider>
        <Routes />
      </UserProvider>
    </Provider>
  );
}

export default App;
